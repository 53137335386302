import React, { useState, useEffect, useCallback } from "react";
import {
  timeMicrosOf,
  updateRequest,
  findCharacterSubstring,
  filterKanji,
} from "../../../common.js";
import { toast } from "react-toastify";
import CardIcon from "../../../assets/icons/cards.svg";
import AnkiForm from "./AnkiForm.js";
import Loading from "../../Loading.js";

export function makeAnkiCardData(data) {
  return {
    word: {
      spans: [{ text: data.original, romaji: data.romaji }],
    },
    sentence: {
      jmdMeaning: data.sentenceTranslation,
      spans: data.sentenceSpans
        ? data.sentenceSpans.map((s) =>
            s.map((e) => ({
              original: e.original,
              pronunciation: e.pronunciation,
            })),
          )
        : [],
    },
    eng_word: data.translation,
    eng_fullsub: data.sentenceTranslation,
    video_frame_src: data.videoFrameSrc,
    custom_fields: (data.customFields || []).map((f) => ({
      title: f.title,
      content: f.content,
    })),
    rubyBreakdown: data.rubyBreakdown,
    wordRubyBreakdown: data.wordRubyBreakdown,
    kanjis: filterKanji(data.characters).map((k) => ({
      kanji: k.original,
      english: k.translations[0],
    })),
    jmdictId: data.jmdictId,
    jmdictText: data.jmdictText,
  };
}

export function createAnkiCard(
  parentBreakdown,
  childBreakdown,
  data,
  posterPath,
  callback,
) {
  data.videoFrameSrc = posterPath;
  updateRequest(
    "/api/anki/new_card",
    {
      data: makeAnkiCardData({
        ...data,
        characters: childBreakdown?.characters,
      }),
      episodeId: data.episodeId,
      timeMicros: timeMicrosOf(parentBreakdown),
      breakdownIndex: parentBreakdown.index,
      wordIndex:
        typeof childBreakdown?.index === "number"
          ? childBreakdown.index
          : undefined,
      sentence: parentBreakdown.original,
      word: childBreakdown?.original ? childBreakdown.original : undefined,
    },
    callback,
  );
}

function getRubyBreakdown(breakdown, original) {
  // Computes the ruby characters for a word.
  // parentBreakdown is assumed to have a `characters` element.  We naively
  // search every substring of characters for the original word to select the
  // subset of characters that need to be included in the ruby breakdown.
  const characters = breakdown?.characters || [];
  const usedCharacters = findCharacterSubstring(characters, original);

  if (usedCharacters === null) {
    const errorMessage = `Error: Could not find ruby characters for ${original} in ${characters
      .map((x) => x.original)
      .join("")}`;
    toast(errorMessage);
    // Fall back to no ruby breakdown.
    return original.split("").map((c) => ({ original: c }));
  }
  let rubyBreakdown = usedCharacters.map((c) => ({
    original: c.original,
    romaji: c.pronunciation,
    kana: c.kana,
  }));
  return rubyBreakdown;
}

export default function AnkiCreator({
  videoPath,
  posterPath,
  episode,
  parentBreakdown,
  childBreakdown,
  morpheme,
  closeCallback,
  createCallback,
  setWordBreakdownKnown,
}) {
  const [loading, setLoading] = useState(true);
  const [ankiDraft, setAnkiDraft] = useState(null);

  const setKnown = useCallback(
    (data) => {
      setWordBreakdownKnown(
        { jmdict_id: data.jmdictId, original: data.original },
        true,
      );
    },
    [setWordBreakdownKnown],
  );

  const safeFirst = (x) => (x && x.length > 0 ? x[0] : "");
  const target = morpheme ? morpheme : childBreakdown;
  const sentenceIndices = parentBreakdown?.words
    .flatMap((c) => c.sentences)
    .filter((s, i, a) => a.indexOf(s) === i);

  // Note: Morphemes don't have pronunciations saved in the breakdown so we have
  // to compute them from the pronunciation of the characters of the word they
  // belong to.
  const pronunciation = morpheme
    ? (
        findCharacterSubstring(
          childBreakdown?.characters,
          morpheme?.original,
        ) || []
      )
        .map((c) => c.pronunciation)
        .join("")
    : childBreakdown?.pronunciation;

  // Create a memo for the kanjis
  const kanjis = React.useMemo(() => {
    return filterKanji(childBreakdown?.characters).map((k) => ({
      kanji: k.original,
      english: k.translations[0],
    }));
  }, [childBreakdown?.characters]);

  useEffect(() => {
    const fetchAnkiDraft = async () => {
      try {
        await updateRequest(
          "/api/anki-draft/",
          {
            word: childBreakdown?.original,
            sentence: parentBreakdown?.original,
            morpheme: morpheme?.original || "",
          },
          (data) => {
            setAnkiDraft(data);
            setLoading(false);
          },
        );
      } catch (error) {
        console.error("Error fetching Anki draft:", error);
        toast.error("Failed to fetch Anki draft. Using default values.");
        setLoading(false);
      }
    };

    fetchAnkiDraft();
  }, [target?.original, parentBreakdown?.original]);

  if (loading) {
    return <Loading />;
  }

  const args = {
    videoPath: videoPath,
    episode: episode,
    isMorpheme: !!morpheme,
    original: target?.original,
    romaji: pronunciation,
    translations: target?.translations || childBreakdown?.translations || [],
    rubyBreakdown: getRubyBreakdown(childBreakdown, target?.original),
    jmdictId: target?.jmdict?.jmdict_id,
    jmdictText: target?.jmdict?.original,
    wordRubyBreakdown: getRubyBreakdown(
      childBreakdown,
      childBreakdown?.original,
    ),
    morphemeOriginal: morpheme?.original,
    morphemeTranslation: safeFirst(morpheme?.translations),
    wordOriginal: childBreakdown?.original,
    wordPronunciation: childBreakdown?.pronunciation,
    wordTranslation: safeFirst(childBreakdown?.translations),
    sentenceOriginal: parentBreakdown?.original,
    sentenceTranslation: safeFirst(parentBreakdown?.translations),
    lineSelection: childBreakdown?.sentences[0] || 0,
    sentence:
      parentBreakdown?.original.split("\n")[childBreakdown?.sentences[0] || 0],
    cardFront:
      ankiDraft?.front ??
      morpheme?.anki?.front ??
      childBreakdown?.translations[0],
    cardBack: ankiDraft?.back ?? morpheme?.anki?.back,
    sentenceElements: sentenceIndices.map((i) =>
      parentBreakdown?.words.filter((c) => c.sentences.includes(i)),
    ),
    childSpan: [
      { text: childBreakdown?.original, romaji: childBreakdown?.pronunciation },
    ],
    cardCustomFields: ankiDraft?.customFields || [],
    cardFieldIndex: 1,
    closeCallback: closeCallback,
    submitCallback: (data) => {
      createAnkiCard(parentBreakdown, childBreakdown, data, posterPath, () => {
        setKnown(data);
        createCallback();
        closeCallback();
      });
      toast(
        <>
          <img src={CardIcon} width="16" height="16" /> Anki card created!
        </>,
      );
    },
    autoGenerateMnemonic: true,
    kanjis: kanjis,
  };

  return <AnkiForm {...args} />;
}
