import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Page from "./layout/Page.js";
import Header from "./layout/Header.js";
import Row from "./layout/Row.js";
import BackButton from "./atoms/BackButton.js";
import Loading from "./Loading.js";
import { logEvent, updateRequest } from "../common.js";
import BreakdownButton from "./atoms/BreakdownButton.js";

const CenteredBox = styled.div`
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const StyledBreakdownButton = styled(BreakdownButton)`
  margin-top: 1rem;
`;

export default function DeleteAccount() {
  const location = useLocation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    document.title = "LingoFlix - Delete Account";
  }, []);

  const handleDeleteAccount = async () => {
    logEvent("press-confirm-delete-account");
    setLoading(true);
    try {
      const params = new URLSearchParams(location.search);
      const token = params.get("token");
      const response = await updateRequest("/delete-account", {
        token,
      });
      if (response.ok) {
        setSuccess(true);
      } else {
        const errorText = await response.text();
        setError(errorText);
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const headerLeftContent = (
    <Row $gap="1rem">
      <BackButton onClick={() => (window.location.href = "/")} />
    </Row>
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <Page>
      <Header $left={headerLeftContent} $center={<h1>Delete Account</h1>} />
      <CenteredBox>
        {success ? (
          <p style={{ color: "green" }}>Account successfully deleted</p>
        ) : (
          <>
            <p>
              Are you sure you want to delete your account? This action cannot
              be undone.
            </p>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <StyledBreakdownButton
              className="breakdown-button"
              onClick={handleDeleteAccount}
            >
              Confirm Delete
            </StyledBreakdownButton>
          </>
        )}
      </CenteredBox>
    </Page>
  );
}
