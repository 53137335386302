import React, { useMemo, useContext } from "react";
import styled from "styled-components";
import Column from "../../layout/Column.js";
import Row from "../../layout/Row.js";
import PopoverButton from "./PopoverButton.js";
import { SettingsContext } from "../../ContextProvider.js";
import {
  containsParathesis,
  displayTranslation,
  writeClipboard,
  filterKanji,
  computeGlobalRank,
} from "../../../common.js";
import { isMobileOnly } from "react-device-detect";

const TRANSLATION_CHARS_SHOWN_PER_WORD = 32;

const PhraseButton = styled.div`
  cursor: pointer;
  border-radius: 5px;
  padding-top: ${({ $main }) => $main && "0.5rem"};
  margin-top: ${({ $main }) => $main && "-0.5rem"};
  transition: 0.2s background;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const PhraseContainer = styled.div`
  text-align: center;
  color: ${({ $isGray }) => ($isGray ? "rgba(140, 140, 140, 0.85)" : "white")};
`;

const Meaning = styled.p`
  font-weight: normal;
  font-size: 1rem;
  margin: 0;
  color: inherit;
  text-align: center;
`;

const SpanReading = styled(Meaning)`
  min-height: 1.5rem;
  font-style: italic;
`;

const SpanText = styled.h1`
  font-weight: 600;
  margin: 0.5rem 0;
  color: inherit;
  text-align: center;
  line-height: 1.2rem;

  font-size: ${isMobileOnly ? "1.1rem" : "1.625rem"};
`;

function removeBracketedPart(str) {
  return str.replace(/\s*\([^)]*\)$/, "");
}

const stopWordPos = ["SYM", "PART", "ADP", "SCONJ"];
// Note: Adding AUX catches a lot of stop words, but it's also seems too
// aggressive. (Less common gramatical stuff that we'd probably want to show
// also gets flagged that way.)

const stopWordTranslationEndings = [
  "particle]",
  "marker]",
  "copula]",
  "tense]",
  "past]",
  "suffix]",
  "auxiliary]",
  "polite]",
  "progressive]",
  "aspect]",
  "imperative]",
];

// Manually added stop words:
const stopWordOriginals = [
  "した",
  "して",
  "しない",
  "します",
  "ます",
  "ない",
  "なかった",
];

function translationIsStopword(translation) {
  return stopWordTranslationEndings.some((ending) =>
    translation.endsWith(ending),
  );
}

export default function Phrase({ breakdown, isWordBreakdownKnown }) {
  const [settings] = useContext(SettingsContext);
  const globalRankThreshold = settings?.globalRankThreshold ?? 500;
  const episodeRankThreshold = settings?.episodeRankThreshold ?? 100;

  const isKnown = useMemo(
    () => isWordBreakdownKnown(breakdown),
    [breakdown, isWordBreakdownKnown],
  );

  const translation = breakdown?.translations?.[0] || "";
  const isStopWord = useMemo(
    () =>
      translation &&
      (stopWordPos.includes(breakdown.pos) ||
        translationIsStopword(translation) ||
        stopWordOriginals.includes(breakdown.original)),
    [breakdown, translation],
  );
  const isGray = useMemo(
    () =>
      !!(
        isStopWord ||
        breakdown.is_proper_name ||
        containsParathesis(breakdown.original) ||
        breakdown.is_parenthetical
      ),
    [breakdown, isStopWord],
  );
  const isRecommendedForGlobalRank = useMemo(() => {
    if (isGray || isKnown || !breakdown?.jmdict) return false;
    const globalRank = computeGlobalRank(breakdown.jmdict);
    return globalRank && globalRank <= globalRankThreshold;
  }, [breakdown, globalRankThreshold, isGray, isKnown]);
  const isRecommendedForEpisodeRank = useMemo(() => {
    if (isGray || isKnown || !breakdown?.jmdict) return false;
    const episodeRank = breakdown.jmdict?.episode_wordrank;
    return episodeRank && episodeRank <= episodeRankThreshold;
  }, [breakdown, episodeRankThreshold, isGray, isKnown]);

  const recommendationStyle = useMemo(() => {
    return isRecommendedForGlobalRank || isRecommendedForEpisodeRank
      ? {
          "box-shadow": "0 0 10px rgba(255, 255, 255, 0.75)",
          "font-weight": "bold",
        }
      : {};
  }, [isRecommendedForGlobalRank, isRecommendedForEpisodeRank]);

  const kanjiChars = useMemo(
    () => filterKanji(breakdown.characters),
    [breakdown],
  );
  const translationLengthLimit = useMemo(
    () =>
      Math.max(
        (TRANSLATION_CHARS_SHOWN_PER_WORD - 2 * kanjiChars.length) /
          Math.max(kanjiChars.length, 1),
        1,
      ),
    [kanjiChars],
  );
  const kanjis = useMemo(() => {
    return kanjiChars.map((k) =>
      displayTranslation(k.original, k.translations[0], translationLengthLimit),
    );
  }, [kanjiChars, translationLengthLimit]);

  const columns = useMemo(() => {
    if (!breakdown) return [];

    const lacksPronunciations =
      !breakdown.characters ||
      breakdown.characters.every((c) => !c.pronunciation);

    if (lacksPronunciations) {
      let text = breakdown.original;
      if (breakdown.is_parenthetical) {
        text = `(${text})`;
      }
      return [{ text, romaji: breakdown.pronunciation }];
    }
    let chars = breakdown.characters.map((c) => ({
      text: c.original,
      romaji: c.pronunciation,
    }));
    if (breakdown.is_parenthetical) {
      chars = chars.map((c, i) => {
        let text = c.text;
        if (i === 0) {
          text = `(${text}`;
        }
        if (i === chars.length - 1) {
          text = `${c.text})`;
        }
        return { ...c, text };
      });
    }
    return chars;
  }, [breakdown]);
  return (
    <PopoverButton breakdown={breakdown}>
      <PhraseButton $main key={breakdown.original} style={recommendationStyle}>
        <PhraseContainer $isGray={isGray}>
          <Column $gap={0}>
            <Row $gap="0.3rem" style={{ justifyContent: "center" }}>
              {columns.map((c, i) => (
                <Column $gap={0} key={i}>
                  <SpanReading
                    onClick={() => {
                      writeClipboard(breakdown.pronunciation);
                    }}
                  >
                    {c.romaji ? c.romaji : "\u00a0"}
                  </SpanReading>
                  <SpanText
                    onClick={() => {
                      writeClipboard(breakdown.original);
                    }}
                  >
                    {c.text}
                  </SpanText>
                </Column>
              ))}
            </Row>
            {!isStopWord && (
              <Meaning
                onClick={() => {
                  writeClipboard(translation);
                }}
              >
                {breakdown.is_parenthetical
                  ? `(${removeBracketedPart(translation)})`
                  : removeBracketedPart(translation)}
              </Meaning>
            )}
            {breakdown &&
            kanjis.length !== 0 &&
            !breakdown.hideKanjiTranslations ? (
              <Meaning>
                (
                {kanjis.map((kanji, kanjiIndex) => (
                  <React.Fragment key={kanjiIndex}>
                    {kanjiIndex > 0 && "; "}
                    <span title={kanji.tooltip}>
                      {kanji.original}&nbsp;{kanji.displayTranslation}
                    </span>
                  </React.Fragment>
                ))}
                )
              </Meaning>
            ) : (
              <Meaning>&#x3000;</Meaning>
              /* Note: This is an ideographic space character to take up the
              vertical space that would otherwise be taken up by the kanji
              translations.  This makes all the entries line up nicely.
              */
            )}
          </Column>
        </PhraseContainer>
      </PhraseButton>
    </PopoverButton>
  );
}
