import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Page from "./layout/Page.js";
import Dialog from "./layout/Dialog.js";
import user from "../assets/icons/user.svg";
import { fetchJson, updateRequest } from "../common.js";
import { Spinner } from "./breakdown/ChatComponent.js";

export async function loginRequest(e, setErrorMsg, navigate, setLoading) {
  e.preventDefault();
  setLoading(true);
  // eslint-disable-next-line quotes
  const user = document.querySelector('input[name="user"]').value;

  try {
    const request = await updateRequest("/api/login/sendtoken", {
      user,
    });

    const jsonResponse = await request.json();

    if (request.status === 202) {
      sessionStorage.setItem("user", user);
      navigate("/sent");
      return;
    }

    if (jsonResponse && jsonResponse.error) {
      setErrorMsg(jsonResponse.error);
    } else {
      setErrorMsg("Unknown error");
      console.error("Login request error:", { request, jsonResponse });
    }
  } catch (err) {
    console.error("Email not sent error:", err);
    setErrorMsg("Unknown error: " + (err.message || err));
  } finally {
    setLoading(false);
  }
}

export default function Login() {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <Page>
      <Dialog>
        <h1>Log In</h1>
        <form
          onSubmit={(e) => loginRequest(e, setErrorMsg, navigate, setLoading)}
        >
          <h3 className="icon">
            <img src={user} alt="user" />
          </h3>
          <h3 className="help">Enter your email to log in with a magic link</h3>
          <div className="input-row">
            <input
              name="user"
              placeholder="Your email"
              onChange={() => setErrorMsg(null)}
              disabled={loading}
            />
          </div>
          {errorMsg ? <div className="error-message">{errorMsg}</div> : null}
          {loading && <Spinner />}
          <div className="button-bar">
            <input
              className="pointer"
              type="submit"
              value={loading ? "Logging in..." : "Log in"}
              disabled={loading}
            />
          </div>
          <div
            className="input-row"
            style={{
              margin: "20px",
              textTransform: "uppercase",
              color: "#979797CC",
              textAlign: "center",
            }}
          >
            or
          </div>
          <div className="button-bar" style={{ marginTop: "0px" }}>
            <input
              type="button"
              className="pointer"
              style={{ backgroundColor: "#f54242" }}
              onClick={(e) => {
                e.preventDefault();
                setLoading(true);
                fetchJson("google-login", (response) => {
                  if (response.error) {
                    setErrorMsg(response.error);
                    setLoading(false);
                  } else {
                    window.location.href = response.url;
                  }
                });
              }}
              value={loading ? "Logging in..." : "Log in with Google"}
              disabled={loading}
            />
          </div>
        </form>
      </Dialog>
    </Page>
  );
}
