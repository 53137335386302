import React from "react";
import styled from "styled-components";
import Card from "./atoms/Card.js";
import DottedBreak from "./DottedBreak.js";
import Word from "./Word.js";
import RankIcon from "../assets/icons/rank.svg";
import IconDeck from "../assets/icons/cards-deck.svg";
import Sentence from "./breakdown/anki/Sentence.js";
import IconLock from "../assets/icons/lock.svg";
import IconLockOpen from "../assets/icons/lock-open.svg";
import IconPeopleArrows from "../assets/icons/people-arrows.svg";

const Poster = styled.img`
  border-radius: 5px;
`;

const SubCard = styled.div`
  width: 17rem;

  & h1 {
    margin-bottom: 0.5rem;
  }
  & .eng_word {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0.7rem 0;
    color: inherit;
    text-align: center;
  }
  & p {
    font-weight: normal;
    font-size: 1rem;
    margin: 0;
    color: inherit;
    text-align: center;
  }
  & .word {
    margin: 1rem auto;
  }
  & .eng_fullsub {
    color: rgba(140, 140, 140, 0.85);
    margin: 1rem auto;
    font-size: 1rem;
  }
  & .icon {
    margin-right: 0.5rem;
    vertical-align: middle;
  }
  & .highlighted {
    font-weight: bold;
    color: #aaaaaa;
  }
`;

// horizontally scrollable
const SentenceWrapper = styled.div`
  display: flex;
  justify-content: center;

  & h1 {
    font-size: 1.125rem;
    color: inherit;
  }
`;

function AnkiCard({
  word,
  rank,
  engWord,
  engFullsub,
  wordRubyBreakdown,
  sentence,
  videoFrameSrc,
  children,
  customFields,
  isInDeck,
  isCreatedByCurrentUser,
  getCardShared,
  getCardSelected,
  setCardSelected,
  kanjis,
}) {
  const original = word.spans.length ? word.spans[0].text : "";
  const formatTags = (engSub) => {
    return new DOMParser()
      .parseFromString(engSub, "text/html")
      .body.innerHTML.replace(",,", "");
  };
  const toggleSelection = () => {
    setCardSelected(!getCardSelected());
  };
  const shareStatusIcon = (
    <img
      src={
        isCreatedByCurrentUser()
          ? getCardShared()
            ? IconLockOpen
            : IconLock
          : IconPeopleArrows
      }
      width="20"
      height="20"
    />
  );

  const highlightEngWord = (text) => {
    const escapedEngWord = engWord.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(`\\b${escapedEngWord}\\b`, "gi");
    const parts = text.split(regex);
    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {index > 0 && <span className="highlighted">{engWord}</span>}
        {part}
      </React.Fragment>
    ));
  };

  return (
    <Card $toggled={getCardSelected()} onClick={toggleSelection}>
      <SubCard>
        <p className="eng_word">
          {isInDeck ? <img src={IconDeck} width="20" height="20"></img> : ""}
          {shareStatusIcon}
          &nbsp; {engWord}
        </p>
        <DottedBreak />
        <div className="word">
          <Word
            rubyBreakdown={wordRubyBreakdown}
            highlight={original}
            underText={
              kanjis
                ? "(" +
                  kanjis.map((k) => k.kanji + " " + k.english).join("; ") +
                  ")"
                : ""
            }
            showUnderText={!!kanjis?.length}
          />
        </div>
        {sentence && (
          <SentenceWrapper>
            <Sentence
              sentenceElements={sentence.spans}
              wordOriginal={word.spans.length ? word.spans[0].text : ""}
              translation={sentence.translation}
              doWrap={true}
            />
          </SentenceWrapper>
        )}
        <p className="eng_fullsub">
          {highlightEngWord(formatTags(engFullsub))}
        </p>
        <Poster src={videoFrameSrc} alt="Image of video scene" height={150} />
        {!!rank && (
          <p className="eng_word">
            <img
              className="icon"
              src={RankIcon}
              alt="Rank icon"
              width="1.15rem"
              height="1.15rem"
            />
            {Math.round((rank / 100 + 1) * 100)}
          </p>
        )}
        {customFields.map((cf, i) => (
          <p key={i}>
            <b>{cf.title}:&nbsp;</b>
            {cf.content}
          </p>
        ))}
      </SubCard>
      {children}
    </Card>
  );
}

export default AnkiCard;
