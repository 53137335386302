import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Page from "./layout/Page.js";
import Dialog from "./layout/Dialog.js";
import Checkmark from "../assets/icons/checkmark-round.svg";
import Header from "./layout/Header.js";
import BackButton from "./atoms/BackButton.js";
import { SessionContext } from "./ContextProvider.js";

export default function SentDeleteLink() {
  const navigate = useNavigate();
  const [session] = useContext(SessionContext);
  const email = "sub" in session ? session.sub : "Error: email not defined";

  useEffect(() => {
    document.title = "Deletion Link Sent - LingoFlix";
  }, []);

  return (
    <Page>
      <Header $left={<BackButton onClick={() => navigate("/")} />} />
      <Dialog>
        <h1>Check your email</h1>
        <h3 className="icon">
          <img src={Checkmark} alt="checkmark" />
        </h3>
        <h3 className="help">
          The link to confirm the account deletion was sent to{" "}
          <span className="value">{email}</span>
        </h3>
        <p>
          Please check your email and click the link to confirm the account
          deletion.
        </p>
        <h4>
          You have <strong>10 minutes</strong> to confirm the deletion.
        </h4>
        <p>Didn’t receive an email? Please check your spam folder.</p>
      </Dialog>
    </Page>
  );
}
