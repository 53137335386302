import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { logEvent, updateRequest, isKanji, debounce } from "../../../common.js";
import DottedBreak from "../../DottedBreak.js";
import Grid from "../../layout/Grid.js";
import Row from "../../layout/Row.js";
import Column from "../../layout/Column.js";
import BreakdownButton from "../../atoms/BreakdownButton.js";
import Word from "../../Word.js";
import AutoGrowTextArea from "../../atoms/AutoGrowTextArea.js";
import Sentence from "./Sentence";
import { toast } from "react-toastify";
import IconTrashCan from "../../../assets/icons/trash-can.svg";
import IconPlus from "../../../assets/icons/plus.svg";
import { Spinner } from "../ChatComponent.js";
import IconPencil from "../../../assets/icons/pencil.svg";
import { SettingsContext } from "../../ContextProvider.js";

function appendToElement(list, i, x) {
  list = list.slice(); // Copy the list
  while (list.length <= i) {
    list.push(""); // Make sure the list is long enough
  }
  list[i] += x; // Then append
  return list;
}

const CloseColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
`;

const AnkiFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  overflow: auto;
  padding-right: 0.5rem; // for scrolling bar positioning

  & h1 {
    font-size: 1.625rem;
    font-weight: 600;
    margin-bottom: 0.7rem;
    color: inherit;
    text-align: center;
  }
  & p {
    color: inherit;
    text-align: center;
  }
  & .lit {
    text-align: center;
    color: white;
  }
  & .muted {
    text-align: center;
    color: rgba(140, 140, 140, 0.85);
  }
`;

const FormContents = styled.div`
  overflow: auto;
  flex-grow: 1;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  padding-right: 0.5rem; // for scrolling bar positioning
`;

const PaddingBottom = styled.div`
  padding-bottom: 1.5rem;
`;

const LightSeparator = styled.hr`
  border: none;
  border-top: 1px solid #e0e0e0;
  padding-bottom: 1rem;
`;

const CustomFieldRow = styled(Row)`
  align-items: flex-start;
  margin-bottom: 1rem;
  width: 100%;
`;

const CustomFieldActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-left: 0.5rem;
`;

export default function AnkiForm({
  videoPath,
  episode,
  isMorpheme,
  original,
  rubyBreakdown,
  wordRubyBreakdown,
  romaji,
  translations,
  kanjis,
  jmdictId,
  jmdictText,

  morphemeOriginal,
  morphemeTranslation,
  wordOriginal,
  wordPronunciation,
  wordTranslation,
  lineSelection,
  sentence,
  sentenceOriginal,
  sentenceTranslation,

  cardFront,
  cardBack,
  sentenceElements,
  cardCustomFields,
  cardFieldIndex,

  closeCallback,
  submitCallback,

  autoGenerateMnemonic,
}) {
  const [settings] = useContext(SettingsContext);

  function onClose(e) {
    e.preventDefault();
    closeCallback();
  }

  const hasKanji = original.split("").some(isKanji);
  const [front, setFront] = useState(cardFront);
  const [back, setBack] = useState(cardBack);
  const [customFieldOptions, setCustomFieldOptions] = useState(
    [
      {
        text: "Add Translation Mnemonic",
        backend: "translation_mnemonic",
        amount: 3,
      },
      hasKanji && {
        text: "Add Kanji Meanings Mnemonic",
        backend: "kanji_meanings_mnemonic",
        amount: 1,
      },
      { text: "Add Explanation", backend: "explanation", amount: 1 },
      translations.length >= 2 && {
        text: isMorpheme ? "Add Context Word" : "Add All Translations",
        action: isMorpheme ? "context_word" : "all_translations",
        amount: 1,
      },
      { text: "Add Example Sentence", backend: "example_sentence", amount: 1 },
      { text: "Add Custom Field", amount: 1 },
    ]
      .filter((o) => !!o)
      .map((o, i) => ({ ...o, index: i })),
  );
  const [customFields, setCustomFields] = useState(cardCustomFields);
  const [fieldIndex, setFieldIndex] = useState(cardFieldIndex);
  const [loadingFieldIndex, setLoadingFieldIndex] = useState(null);
  const [editingField, setEditingField] = useState(null);

  const debouncedSaveToBackend = useCallback(
    debounce((data) => {
      updateRequest("/api/anki-draft/update", data, () => {}, true).catch(
        () => {
          toast.error("Failed to save draft of Anki card.");
        },
      );
    }, 1000),
    [],
  );

  useEffect(() => {
    debouncedSaveToBackend({
      word: wordOriginal,
      sentence: sentenceOriginal,
      morpheme: morphemeOriginal || "",
      data: { front, back, customFields },
    });
  }, [
    front,
    back,
    customFields,
    wordOriginal,
    sentenceOriginal,
    morphemeOriginal,
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    logEvent("click-save-anki");
    const data = {
      videoPath,
      episodeId: episode.id,
      jmdMeaning: translations.length > 0 ? translations[0] : "",
      rubyBreakdown: rubyBreakdown,
      wordRubyBreakdown: wordRubyBreakdown,
      original: original,
      romaji: romaji,
      translation: front,
      customFields: customFields.map((field) => ({
        title: field.title,
        content: field.content[field.selectedOption],
      })),
      sentenceSpans: sentenceElements,
      jmdictId,
      jmdictText,

      cardBack: back,
      cardFront: front,

      morphemeOriginal,
      morphemeTranslation,
      wordOriginal,
      wordPronunciation,
      wordTranslation,
      sentenceOriginal,
      sentenceTranslation,
      lineSelection,
    };
    submitCallback(data, closeCallback);
  };

  const constructCustomContent = (action) => {
    if (action === "all_translations") {
      return translations.join(", ");
    } else if (action === "context_word") {
      return (
        "This Anki card was inspired by " +
          morphemeOriginal +
          " (interpreted as '" +
          morphemeTranslation +
          "') in the word " +
          wordOriginal +
          " (" +
          wordPronunciation +
          ") which means '" +
          wordTranslation +
          "' in the sentence " +
          sentenceOriginal,
        "."
      );
    }
    return "";
  };

  const doBackendRequest = (option, index, choice, language = null) => {
    setLoadingFieldIndex(index);
    const payload = {
      sentence,
      word: wordOriginal,
      wordTranslation: wordTranslation,
    };
    if (
      settings?.languages &&
      settings.languages.length > 1 &&
      !(settings.languages.length === 1 && settings.languages[0] === "English")
    ) {
      payload.languages = language ? [language] : settings.languages;
    }
    updateRequest(
      "/api/anki-chat/" + option,
      payload,
      (response) => {
        const reader = response.body.getReader();
        return reader.read().then(function processResult(result) {
          if (result.done) {
            setLoadingFieldIndex(null);
            return;
          }
          const message = new TextDecoder().decode(result.value);
          setCustomFields((customFields) =>
            customFields.map((f) =>
              f.index === index
                ? {
                    ...f,
                    content: appendToElement(f.content, choice, message),
                  }
                : f,
            ),
          );
          return reader.read().then(processResult);
        });
      },
      true,
    ).catch((e) => {
      console.error(e);
      toast("Failed to fetch data for custom field.");
      setLoadingFieldIndex(null);
    });
  };

  const removeCustomField = (field) => {
    setCustomFields((customFields) =>
      customFields.filter((f) => f.index !== field.index),
    );
    if (!field.option.action && !field.option.backend) return;
    setCustomFieldOptions((options) => {
      return [...options, field.option].sort((a, b) => a.index - b.index);
    });
  };

  const removeCustomFieldContent = (fieldIndex, contentIndex) => {
    setCustomFields((customFields) =>
      customFields.map((f) =>
        f.index === fieldIndex
          ? {
              ...f,
              content: f.content.filter((_, i) => i !== contentIndex),
              selectedOption:
                f.selectedOption >= contentIndex
                  ? Math.max(f.selectedOption - 1, 0)
                  : f.selectedOption,
            }
          : f,
      ),
    );
  };

  const selectCustomFieldOption = (fieldIndex, optionIndex) => {
    // if this is already selected, don't do anything
    if (
      customFields.find((f) => f.index === fieldIndex).selectedOption ===
      optionIndex
    ) {
      return;
    }
    setEditingField(null);
    setCustomFields((customFields) =>
      customFields.map((f) =>
        f.index === fieldIndex
          ? {
              ...f,
              selectedOption: optionIndex,
            }
          : f,
      ),
    );
  };

  const createCustomField = (option) => {
    const index = fieldIndex;
    setFieldIndex((index) => index + 1);

    let contentAmount = option.amount;
    let languages = settings?.languages || [];

    if (
      option.backend &&
      option.backend.includes("mnemonic") &&
      languages.length > 1
    ) {
      contentAmount = languages.length + 1;
    }

    setCustomFields([
      ...customFields,
      {
        index,
        title: option.text.replace("Add ", ""),
        content: Array(contentAmount).fill(
          constructCustomContent(option.action),
        ),
        selectedOption: 0,
        option,
      },
    ]);
    if (option.action || option.backend) {
      setCustomFieldOptions((options) => options.filter((o) => o !== option));
    }
    if (option.backend) {
      if (option.backend.includes("mnemonic") && languages.length > 1) {
        languages.forEach((lang, i) => {
          doBackendRequest(option.backend, index, i, lang);
        });
        doBackendRequest(option.backend, index, languages.length);
      } else {
        for (let i = 0; i < contentAmount; i++) {
          doBackendRequest(option.backend, index, i);
        }
      }
    }
  };

  const addCustomFieldContent = (fieldIndex) => {
    setCustomFields((customFields) =>
      customFields.map((f) =>
        f.index === fieldIndex
          ? {
              ...f,
              content: [...f.content, ""],
            }
          : f,
      ),
    );
    if (customFields.find((f) => f.index === fieldIndex).option.backend) {
      doBackendRequest(
        customFields.find((f) => f.index === fieldIndex).option.backend,
        fieldIndex,
        customFields.find((f) => f.index === fieldIndex).content.length,
      );
    }
  };

  // Autogenerate a translation mnemonic:
  useEffect(() => {
    const translationMnemonic = customFieldOptions.find(
      (o) => o.backend === "translation_mnemonic",
    );
    if (
      autoGenerateMnemonic &&
      translationMnemonic &&
      customFields.length === 0
    ) {
      createCustomField(translationMnemonic);
    }
  }, []);

  return (
    <AnkiFormWrapper onSubmit={handleSubmit}>
      <FormContents>
        <DottedBreak>Front</DottedBreak>
        <AutoGrowTextArea
          value={front}
          onChange={(e) => setFront(e.target.value)}
          $boldText
          $centerText
        />
        <DottedBreak>Back</DottedBreak>
        <CloseColumn>
          <AutoGrowTextArea
            value={back}
            onChange={(e) => setBack(e.target.value)}
            $centerText
          />
        </CloseColumn>
        <Word
          rubyBreakdown={wordRubyBreakdown || rubyBreakdown}
          highlight={original}
          underText={
            kanjis
              ? "(" +
                kanjis.map((k) => k.kanji + " " + k.english).join("; ") +
                ")"
              : ""
          }
          showUnderText={kanjis.length > 0}
        />
        <LightSeparator style={{ marginTop: kanjis.length > 0 ? "1rem" : 0 }} />
        <PaddingBottom>
          <Sentence
            sentenceElements={sentenceElements}
            wordOriginal={wordOriginal}
            sentenceTranslation={sentenceTranslation}
            wordTranslation={wordTranslation}
          />
        </PaddingBottom>
        {customFields.length > 0 && (
          <Column $center $fullWidth>
            {customFields.map((field, i) => (
              <CustomFieldRow key={i}>
                <div style={{ flex: 1 }}>
                  <Row style={{ marginBottom: "0.5rem" }}>
                    <AutoGrowTextArea
                      value={field.title}
                      onChange={(e) =>
                        setCustomFields((customFields) =>
                          customFields.map((f) =>
                            f.index === field.index
                              ? { ...f, title: e.target.value }
                              : f,
                          ),
                        )
                      }
                      $centerText
                    />
                    <CustomFieldActions>
                      <BreakdownButton
                        type="button"
                        className="breakdown-button"
                        onClick={() => removeCustomField(field)}
                        $fitContent
                        $noCapitalize
                      >
                        <img src={IconTrashCan} width="16" height="16" />
                      </BreakdownButton>
                    </CustomFieldActions>
                  </Row>
                  {field.content.map((content, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "0.5rem",
                        position: "relative",
                      }}
                    >
                      <AutoGrowTextArea
                        id={`field_${field.index}_${index}`}
                        value={content}
                        readOnly={
                          editingField !== `${field.index}_${index}` &&
                          field.option.backend
                        }
                        onClick={() =>
                          selectCustomFieldOption(field.index, index)
                        }
                        onChange={(e) => {
                          setCustomFields((customFields) =>
                            customFields.map((f) =>
                              f.index === field.index
                                ? {
                                    ...f,
                                    content: f.content.map((c, i) =>
                                      i === index ? e.target.value : c,
                                    ),
                                  }
                                : f,
                            ),
                          );
                        }}
                        $centerText
                        style={{
                          flex: 1,
                          cursor: "pointer",
                          backgroundColor:
                            field.selectedOption === index
                              ? "rgba(255, 255, 255, 0.05)"
                              : "transparent",
                          color:
                            field.selectedOption === index
                              ? "rgba(255, 255, 255, 0.85)"
                              : "rgba(140, 140, 140, 0.85)",
                        }}
                      />
                      {field.option.backend &&
                        field.selectedOption === index && (
                          <BreakdownButton
                            type="button"
                            className="breakdown-button"
                            $fitContent
                            $noCapitalize
                            onClick={() => {
                              setEditingField(`${field.index}_${index}`);
                              document
                                .getElementById(`field_${field.index}_${index}`)
                                .focus();
                            }}
                            style={{
                              position: "absolute",
                              right: "60px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              background: "none",
                              border: "none",
                            }}
                          >
                            <img src={IconPencil} width="20" height="20" />
                          </BreakdownButton>
                        )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "0.5rem",
                        }}
                      >
                        <BreakdownButton
                          type="button"
                          className="breakdown-button"
                          onClick={() =>
                            removeCustomFieldContent(field.index, index)
                          }
                          $fitContent
                          $noCapitalize
                          disabled={field.content.length === 1}
                        >
                          <img
                            src={IconTrashCan}
                            width="16"
                            height="16"
                            style={{
                              opacity: field.content.length === 1 ? 0.5 : 1,
                            }}
                          />
                        </BreakdownButton>
                      </div>
                    </div>
                  ))}
                  {field.option.backend && (
                    <BreakdownButton
                      type="button"
                      className="breakdown-button"
                      onClick={() => addCustomFieldContent(field.index)}
                      $fitContent
                      $noCapitalize
                      style={{ width: "100%", marginTop: "0.5rem" }}
                    >
                      <img src={IconPlus} width="16" height="16" />
                    </BreakdownButton>
                  )}
                </div>
              </CustomFieldRow>
            ))}
          </Column>
        )}
        <Column $center>
          {customFieldOptions.map((option, i) => (
            <BreakdownButton
              key={i}
              className="breakdown-button"
              type="button"
              onClick={() => createCustomField(option)}
              $fitContent
              $noCapitalize
            >
              {option.text}
            </BreakdownButton>
          ))}
        </Column>
      </FormContents>
      <Grid>
        <BreakdownButton className="breakdown-button" onClick={onClose}>
          cancel
        </BreakdownButton>
        <BreakdownButton
          className="breakdown-button"
          type="submit"
          $primary
          disabled={loadingFieldIndex !== null}
        >
          {loadingFieldIndex !== null ? <Spinner size="8px" /> : "save"}
        </BreakdownButton>
      </Grid>
    </AnkiFormWrapper>
  );
}
