import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UserMenu from "./UserMenu.js";
import InviteMenu from "./InviteMenu.js";
import SentDeleteLink from "./SentDeleteLink.js";
import AnkiCardView from "./AnkiCardView.js";
import Home from "./home/Home.js";
import Show from "./home/Show.js";
import Movie from "./breakdown/Movie.js";
import Page from "./layout/Page.js";
import ChatOverview from "./ChatOverview.js";
import Settings from "./Settings.js";
import Sent from "./Sent.js";
import DeleteAccount from "./DeleteAccount.js";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sent" element={<Sent />} />
        <Route path="/browse/:show" element={<Show />} />
        <Route path="/watch/:show/:episode" element={<Movie />} />
        <Route path="/user-menu" element={<UserMenu />} />
        <Route path="/sent-delete-link" element={<SentDeleteLink />} />
        <Route path="/delete-account" element={<DeleteAccount />} />
        <Route path="/invite-menu" element={<InviteMenu />} />
        <Route path="/chat" element={<ChatOverview />} />
        <Route path="/settings" element={<Settings />} />
        <Route
          path="/anki-card-view"
          element={
            <Page>
              <AnkiCardView />
            </Page>
          }
        />
        <Route
          path="/anki-card-view/:movieId/:sceneNr"
          element={<AnkiCardView />}
        />
        <Route
          path="/shared/anki-card-view/:cardUuids"
          element={<AnkiCardView />}
        />
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
}
