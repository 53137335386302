import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchJson } from "../../common.js";
import Page from "../layout/Page.js";
import CollectionRow from "../layout/CollectionRow.js";
import VideosList from "./VideosList.js";
import Header from "../layout/Header.js";
import BackButton from "../atoms/BackButton.js";
import Loading from "../Loading.js";

export default function ShowPage() {
  const { show: showUrl } = useParams();
  const [show, setShow] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchJson(`/api/episode/${showUrl}`)
      .then((show) => ({
        ...show,
        episodes: show.episodes.map((episode) => ({
          ...episode,
          showUrl: show.url,
        })),
      }))
      .then(setShow)
      .catch((e) => {
        console.error("ERROR fetching shows", e);
        setError("Error fetching shows");
      });
  }, [showUrl]);

  useEffect(() => {
    document.title = `${show?.title ?? "Unknown Show"} - LingoFlix`;
  }, [show]);

  if (show === null) {
    return <Loading />;
  }
  if (error) {
    return (
      <div>
        <h1>Error Loading Shows: </h1>
        <pre style={{ color: "red" }}>{error}</pre>
      </div>
    );
  }

  if (show?.episodes?.length === 0) {
    return <h1>No episodes</h1>;
  }
  const headerLeftContent = (
    <BackButton
      onClick={() => {
        navigate("/");
      }}
    />
  );
  const headerCenterContent = <h1>{show.title}</h1>;

  return (
    <Page>
      <Header $left={headerLeftContent} $center={headerCenterContent} />
      <CollectionRow $wrap>
        <VideosList tiles={show.episodes} wrap />
      </CollectionRow>
    </Page>
  );
}
