import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { logEvent } from "../common.js";
import Page from "./layout/Page.js";
import BackButton from "./atoms/BackButton.js";
import { SettingsContext } from "./ContextProvider.js";
import Header from "./layout/Header.js";
import Row from "./layout/Row.js";
import LanguageDropdown from "./atoms/LanguageDropdown.js";
import { toast } from "react-toastify";

const SettingsContainer = styled.div`
  margin: auto;
  max-width: 600px;
  padding: 20px;
  color: ${({ theme }) => theme.text};
`;

const LanguageList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const LanguageItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const DeleteButton = styled.button`
  background-color: #ff4136;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
`;

const SectionTitle = styled.h2`
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const StyledCheckbox = styled.input`
  margin-right: 1rem;
`;

const CheckboxLabel = styled.label`
  cursor: pointer;
`;

const InputLabel = styled.label`
  margin-right: 1rem;
  text-align: center;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 0.25rem;
`;

export default function Settings() {
  const navigate = useNavigate();
  const [settings, setSettings] = useContext(SettingsContext);

  const languages = settings?.languages || ["English"];
  const allowedContent = settings?.allowedContent || [];

  const headerLeftContent = (
    <Row $gap="1rem">
      <BackButton onClick={() => navigate("/user-menu")} />
    </Row>
  );
  const headerCenterContent = <h1>Settings</h1>;

  const handleUpdateSettings = async (field, value, description) => {
    const updatedSettings = { ...settings, [field]: value };
    setSettings(updatedSettings);
    try {
      const response = await fetch("/api/settings/update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedSettings),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to update settings");
      }

      setSettings(updatedSettings);
      toast.success(`${description} updated successfully`);
      return true;
    } catch (error) {
      console.error("Error updating settings:", error);
      toast.error(`Failed to update ${description}. Please try again.`);
      return false;
    }
  };

  const handleAddLanguage = async (selectedLanguage) => {
    selectedLanguage = selectedLanguage.name;
    if (selectedLanguage && !languages.includes(selectedLanguage)) {
      const newLanguages = [...languages, selectedLanguage];
      const result = await handleUpdateSettings(
        "languages",
        newLanguages,
        `Support for ${selectedLanguage}`,
      );
      if (result) {
        logEvent("add-language", { language: selectedLanguage });
      }
    }
  };

  const validatePositiveInteger = (event) => {
    const value = event.target.value;
    const parsedValue = parseInt(value, 10);
    if (isNaN(parsedValue) || parsedValue <= 0) {
      event.target.style.color = "red";
      toast.error("Setting must be a positive integer");
      return null;
    }
    event.target.style.color = "black";
    return parsedValue;
  };

  const handleUpdateGlobalRankThreshold = async (event) => {
    const value = validatePositiveInteger(event);
    if (value === null) {
      return true;
    }
    const result = await handleUpdateSettings(
      "globalRankThreshold",
      value,
      "Global Rank Threshold",
    );
    if (result) {
      logEvent("update-global-rank-threshold", { value });
    }
  };

  const handleUpdateEpisodeRankThreshold = async (event) => {
    const value = validatePositiveInteger(event);
    if (value === null) {
      return true;
    }
    const result = await handleUpdateSettings(
      "episodeRankThreshold",
      value,
      "Episode Rank Threshold",
    );
    if (result) {
      logEvent("update-episode-rank-threshold", { value });
    }
  };

  const handleDeleteLanguage = async (language) => {
    const newLanguages = languages.filter((lang) => lang !== language);
    const result = await handleUpdateSettings(
      "languages",
      newLanguages,
      `Support for ${language}`,
    );
    if (result) {
      logEvent("delete-language", { language });
    }
  };

  const handleContentChange = async (service) => {
    const newAllowedContent = allowedContent.includes(service)
      ? allowedContent.filter((item) => item !== service)
      : [...allowedContent, service];
    const result = await handleUpdateSettings(
      "allowedContent",
      newAllowedContent,
      `Content ownership for ${service}`,
    );
    if (result) {
      logEvent("update-content-ownership", {
        service,
        checked: newAllowedContent.includes(service),
      });
    }
  };

  useEffect(() => {
    document.title = "Settings - LingoFlix";
  }, []);

  return (
    <Page>
      <Header $left={headerLeftContent} $center={headerCenterContent} />
      <SettingsContainer>
        <SectionTitle>Languages</SectionTitle>
        <LanguageList>
          {languages.map((language) => (
            <LanguageItem key={language}>
              {language}
              {language !== "English" && (
                <DeleteButton onClick={() => handleDeleteLanguage(language)}>
                  Delete
                </DeleteButton>
              )}
            </LanguageItem>
          ))}
        </LanguageList>
        <Row $gap="1rem">
          <LanguageDropdown onSelect={handleAddLanguage} />
        </Row>

        <SectionTitle>Recommendation Thresholds</SectionTitle>
        <Row $gap="1rem" $center>
          <Input
            type="number"
            value={settings.globalRankThreshold ?? "500"}
            onChange={handleUpdateGlobalRankThreshold}
          />
          <InputLabel>Global Rank Threshold</InputLabel>
        </Row>
        <Row $gap="1rem" $center>
          <Input
            type="number"
            value={settings.episodeRankThreshold ?? "100"}
            onChange={handleUpdateEpisodeRankThreshold}
          />
          <InputLabel>Episode Rank Threshold</InputLabel>
        </Row>

        <SectionTitle>Content Ownership</SectionTitle>
        <CheckboxContainer>
          <StyledCheckbox
            type="checkbox"
            id="netflix"
            checked={allowedContent.includes("netflix")}
            onChange={() => handleContentChange("netflix")}
          />
          <CheckboxLabel htmlFor="netflix">
            I&apos;m a paid subscriber to Netflix
          </CheckboxLabel>
        </CheckboxContainer>
        <CheckboxContainer>
          <StyledCheckbox
            type="checkbox"
            id="youtube"
            checked={allowedContent.includes("youtube")}
            onChange={() => handleContentChange("youtube")}
          />
          <CheckboxLabel htmlFor="youtube">
            I&apos;m a paid subscriber to YouTube
          </CheckboxLabel>
        </CheckboxContainer>
      </SettingsContainer>
    </Page>
  );
}
