import React, { useState, useEffect, useRef, useMemo } from "react";
import styled from "styled-components";
import {
  updateRequest,
  containsJapanese,
  timeMicrosOf,
  formatDate,
} from "../../common";
import BreakdownButton from "../atoms/BreakdownButton.js";
import TextInput from "../atoms/TextInput.js";
import Row from "../layout/Row.js";
import { isDesktop } from "react-device-detect";
import { createAnkiCard } from "./anki/AnkiCreator.js";
import useStore from "../../store.js";
import { toast } from "react-toastify";

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: auto;
  margin-right: 0.5rem; // scrolling bar positioning
`;

const AnkiCard = styled.div`
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 16px;
  padding: 16px;
`;

const Bubble = styled.div`
  padding: 10px;
  // border-radius: 10px; // add it back if you want more natural curves for the bubble pointy part
  background-color: ${(props) => (props.$isUser ? "#0084ff" : "#f2f2f2")};
  color: ${(props) => (props.$isUser ? "#fff" : "#000")};
  margin-bottom: 6px;
  margin-top: 8px;
  margin-right: ${(props) => (props.$isUser ? "0" : "50px")};
  margin-left: ${(props) => (props.$isUser ? "50px" : "0")};
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: left;
  font-size: 1rem;
  --r: 1rem; /* the radius */
  --t: 0.75rem; /* the size of the tail */

  padding: calc(2 * var(--r) / 3);
  -webkit-mask:
    radial-gradient(var(--t) at var(--_d) 0, #0000 98%, #000 102%) var(--_d)
      100% / calc(100% - var(--r)) var(--t) no-repeat,
    conic-gradient(at var(--r) var(--r), #000 75%, #0000 0) calc(var(--r) / -2)
      calc(var(--r) / -2) padding-box,
    radial-gradient(50% 50%, #000 98%, #0000 101%) 0 0 / var(--r) var(--r) space
      padding-box;

  ${(props) =>
    !props.$isUser
      ? `
          --_d: 0%;
          border-left: var(--t) solid #0000;
          margin-right: var(--t);
          place-self: start;
        ` // left...
      : // or right:
        ` 
          --_d: 100%;
          border-right: var(--t) solid #0000;
          margin-left: var(--t);
          place-self: end;
        `}
`;

export const Spinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: ${(props) => props.size || "20px"};
  height: ${(props) => props.size || "20px"};
  animation: spin 2s linear infinite;
  margin: 10px auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ScrollPane = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  max-height: 80%;
  flex-grow: 1;
`;

const MessageBox = styled.div`
  margin-top: 8px;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  margin: 1rem;
`;

const SuggestionsTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.7rem;
  margin-top: 2rem;
`;

export function ChatComponent({
  episode,
  parentBreakdown,
  childBreakdown,
  showSuggestions,
  setShowSuggestions,
  suggestions,
  messages,
  setMessages,
  activeThread,
  setActiveThread,
  newShared,
  setThreads,
  scrollStart,
  setFresh,
  setThreadName,
}) {
  const setAnkiStateDirty = useStore((state) => state.setAnkiStateDirty);
  const [isLoading, setIsLoading] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const chatboxRef = useRef(null);
  const scrollPaneRef = useRef(null);
  const kanjiButtons = useMemo(() => {
    const kanji = childBreakdown
      ? [childBreakdown.original]
      : (parentBreakdown.words || [])
          .map((c) => c.original)
          .filter((c, i, a) => a.findIndex((c2) => c2 === c) === i);
    (childBreakdown ? childBreakdown.original : parentBreakdown.original)
      .split("")
      .filter(containsJapanese)
      .filter((c) => !kanji.includes(c))
      .filter((c, i, a) => a.findIndex((c2) => c2 === c) === i)
      .forEach((c) => kanji.push(c));
    return kanji;
  }, [parentBreakdown, childBreakdown]);

  useEffect(() => {
    if (scrollPaneRef.current) {
      scrollPaneRef.current.scrollTop = scrollStart
        ? 0
        : scrollPaneRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (chatboxRef.current && isDesktop) {
      chatboxRef.current.focus();
    }
  }, []);

  const handleInputChange = (event) => {
    setNewMessage(event.target.value);
  };

  const constructAndSendMessage = (chatMessage, callback, thread) => {
    const userMessage = {
      isUser: true,
      message: chatMessage,
      timestamp: formatDate(new Date()),
    };
    const responseMessage = {
      isUser: false,
      message: "",
      timestamp: formatDate(new Date()),
    };
    setMessages((prevMessages) => [
      ...prevMessages,
      userMessage,
      responseMessage,
    ]);
    setNewMessage("");
    updateRequest(
      "/api/chat",
      {
        thread: thread.id,
        sentence: parentBreakdown.original,
        word: childBreakdown ? childBreakdown.original : undefined,
        prompt: chatMessage,
      },
      (response) => {
        const reader = response.body.getReader();
        let messageText = "";
        return reader.read().then(function processResult(result) {
          if (result.done) {
            setIsLoading(false);
            if (callback) {
              const callbackRes = callback(messageText);
              if (callbackRes) {
                setMessages((messages) => [...messages, callbackRes]);
              }
            }
            try {
              updateRequest(
                "/api/chat/rename_thread",
                { thread: thread.id },
                (res) => {
                  if ("name" in res) {
                    setThreadName(res.name);
                  }
                  if ("error" in res) {
                    console.error(res.error);
                    toast(`Error renaming thread ${res.error}`);
                  }
                },
              );
            } catch (e) {
              console.error(e);
              toast(`Exception when renaming thread ${e}`);
            }
            return;
          }
          const message = new TextDecoder().decode(result.value);
          const lastMessageConst = messageText;
          setMessages((prevMessages) => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            // Verify the last message starts with messageText, in case the user switches threads
            if (lastMessage.message.startsWith(lastMessageConst)) {
              return [
                ...prevMessages.slice(0, -1),
                {
                  ...lastMessage,
                  message: lastMessage.message + message,
                },
              ];
            }
            return prevMessages;
          });
          messageText += message;
          return reader.read().then(processResult);
        });
      },
      true,
    );
  };

  const sendChatMessage = (chatMessage, callback) => {
    setFresh(false);
    if (chatMessage.trim() === "" || isLoading) {
      return;
    }
    setShowSuggestions(false);
    setIsLoading(true);
    if (activeThread && activeThread.isMine) {
      constructAndSendMessage(chatMessage, callback, activeThread);
    } else if (activeThread && !activeThread.isMine) {
      try {
        updateRequest(
          "/api/chat/clone_thread",
          { thread: activeThread.id },
          (res) => {
            if (res.error) {
              toast(`Error cloning thread ${res.error}`);
            } else {
              setActiveThread(res);
              setThreadName(res.name);
              setThreads((threads) => [...threads, res]);
              constructAndSendMessage(chatMessage, callback, res);
            }
          },
        );
      } catch (e) {
        console.error(e);
        toast(`Exception when cloning thread ${e}`);
      }
    } else {
      try {
        updateRequest(
          "/api/chat/create_thread",
          {
            timeMicros: timeMicrosOf(parentBreakdown), // TODO: This changes to `childBreakdown` once children have their own time
            episodeId: episode.id,
            breakdownIndex: parentBreakdown.index,
            wordIndex: childBreakdown ? childBreakdown.index : undefined,
            sentence: parentBreakdown.original,
            word: childBreakdown ? childBreakdown.original : undefined,
            prompt: chatMessage,
            shared: newShared,
          },
          (res) => {
            setActiveThread(res);
            setThreadName(res.name);
            setThreads((threads) => [...threads, res]);
            constructAndSendMessage(chatMessage, callback, res);
          },
        );
      } catch (e) {
        console.error(e);
        toast(`Exception when creating thread ${e}`);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !isLoading) {
      sendChatMessage(newMessage);
    }
  };

  const kanjiButtonClicked = (c) => {
    if (isLoading) {
      return;
    }
    setNewMessage(newMessage + c);
    if (isDesktop) {
      chatboxRef.current.focus();
    }
  };

  return (
    <ChatContainer>
      <ScrollPane ref={scrollPaneRef}>
        {!!messages.length &&
          messages.map((message, i) =>
            message ? (
              <div key={i} style={{ position: "relative", display: "grid" }}>
                <Bubble $isUser={message.isUser} className="swiper-no-swiping">
                  {message.ankiCards
                    ? message.ankiCards.map((card, j) => (
                        <AnkiCard key={j}>
                          <div>
                            <b>Front:</b> {card.translation}
                          </div>{" "}
                          <div>
                            <b>Back:</b> {card.original}
                          </div>
                          {!card.invisible && (
                            <BreakdownButton
                              className="breakdown-button"
                              onClick={() => {
                                createAnkiCard(
                                  parentBreakdown,
                                  childBreakdown,
                                  card,
                                  setAnkiStateDirty,
                                );
                                // Make the button invisible:
                                setMessages((messages) => {
                                  const newMessages = [...messages];
                                  newMessages[i].ankiCards[j].invisible = true;
                                  return newMessages;
                                });
                              }}
                              style={{ marginTop: "8px" }}
                              $primary
                            >
                              add Card.
                            </BreakdownButton>
                          )}
                        </AnkiCard>
                      ))
                    : message.message}
                </Bubble>
                <div style={{ clear: "both" }} />
                <div
                  style={{
                    padding: "0 0.3rem",
                    textAlign: message.isUser ? "right" : "left",
                  }}
                >
                  {message.timestamp}
                </div>
              </div>
            ) : null,
          )}
        {showSuggestions && (
          <div style={{ textAlign: "center" }}>
            <SuggestionsTitle>Suggestions:</SuggestionsTitle>
            {suggestions.map((s, i) => (
              <ButtonWrapper key={i}>
                <BreakdownButton
                  className="breakdown-button"
                  visible={s["visible"]}
                  onClick={() => sendChatMessage(s["prompt"], s["callback"])}
                  $fitContent
                  $noCapitalize
                >
                  {s["name"]}
                </BreakdownButton>
              </ButtonWrapper>
            ))}
          </div>
        )}
        {isLoading && <Spinner />}
      </ScrollPane>
      <MessageBox>
        <Row $gap="1rem">
          <TextInput
            type="text"
            value={newMessage}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            ref={chatboxRef}
            disabled={isLoading}
          />
          <BreakdownButton
            className="breakdown-button"
            onClick={() => sendChatMessage(newMessage)}
            disabled={isLoading}
            $primary
          >
            Send
          </BreakdownButton>
        </Row>
      </MessageBox>
      <Row $gap="1rem" $pb="8px" $scrollable className="swiper-no-swiping">
        {(kanjiButtons || []).map((c, i) => (
          <BreakdownButton
            key={i}
            className="breakdown-button"
            onClick={() => kanjiButtonClicked(c)}
            $fitContent
            $noWrap
          >
            {c}
          </BreakdownButton>
        ))}
      </Row>
    </ChatContainer>
  );
}
