import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { logEvent } from "../common.js";
import Page from "./layout/Page.js";
import BackButton from "./atoms/BackButton.js";
import { SessionContext } from "./ContextProvider.js";
import Header from "./layout/Header.js";
import Row from "./layout/Row.js";

const ButtonMenu = styled.div`
  margin: auto;
  & form {
    margin-top: 10%;
    & button {
      margin: auto;
      margin-bottom: 30px;
      display: block;
      box-sizing: border-box;
      text-align: center;
      border-radius: 10px;
      background-color: black;
      font-size: 1.125rem;
      padding: 1.25rem 2rem;
      min-width: 13rem;
      letter-spacing: 1px;
      color: white;
      border: 1px solid rgba(151, 151, 151, 0.8);
      text-transform: uppercase;
      cursor: pointer;
      pointer-events: all;
      transition: 0.2s border;
      font-weight: 600;

      &:hover {
        border-color: var(--azure);
      }
      &:focus {
        outline: none;
        border-color: var(--azure);
      }
    }
  }
`;

export default function UserMenu() {
  const navigate = useNavigate();
  const [session] = useContext(SessionContext);
  const sessionName = session.name;
  const sessionEmail = session.sub;
  const headerLeftContent = (
    <Row $gap="1rem">
      <BackButton onClick={() => navigate("/")} />
    </Row>
  );
  const headerCenterContent = (
    <div>
      <h1>{sessionName}</h1>
      <p className="secondary">{sessionEmail}</p>
    </div>
  );

  useEffect(() => {
    document.title = "Menu - LingoFlix";
  }, []);

  return (
    <Page>
      <Header $left={headerLeftContent} $center={headerCenterContent} />
      <ButtonMenu>
        <form>
          <button
            type="button"
            onClick={() => {
              navigate("/invite-menu");
              logEvent("press-invite-friend");
            }}
          >
            Invite Friend
          </button>
          <button
            type="button"
            onClick={() => {
              navigate("/settings");
              logEvent("press-settings");
            }}
          >
            Settings
          </button>
          <button
            type="button"
            onClick={async (e) => {
              e.preventDefault();
              logEvent("press-delete-account");
              try {
                const result = await fetch("/api/login/send-delete-link", {
                  method: "POST",
                  credentials: "include",
                });
                if (result.ok) window.location.href = "/sent-delete-link";
                else console.error("error", result);
              } catch (e) {
                console.error("send delete link fetch error", e.message);
              }
            }}
          >
            Delete Account
          </button>
          <button
            type="button"
            onClick={async (e) => {
              e.preventDefault();
              logEvent("press-log-out");
              try {
                const result = await fetch("/api/login/logout", {
                  credentials: "include",
                });
                if (result.ok) window.location.href = "/";
                else console.error("error", result);
              } catch (e) {
                console.error("logout fetch error", e.message);
              }
            }}
          >
            Log Out
          </button>
        </form>
      </ButtonMenu>
    </Page>
  );
}
